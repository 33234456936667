class FetchError extends Error {
    status;

    constructor(status, message) {
        super(message);

        this.status = status;
    }
}

/**
 * @param {Response} response
 * @return {Promise<Response>}
 * @throws {FetchError}
 */
const parseHttpResponse = response => {
    const contentType = response.headers.get('Content-Type');
    const isJson = (typeof contentType === 'string' && contentType.indexOf('application/json') === 0);
    let promise;

    if (isJson) {
        promise = response.json();
    } else {
        promise = response.text();
    }

    return promise.then(payload => {
        if (!response.ok) {
            let message = 'Okänt fel';
            if (isJson && payload.message) {
                message = payload.message;
            }

            throw new FetchError(response.status, message);
        }

        return payload;
    });
}

/**
 * @param {RequestInfo} input
 * @param {?RequestInit} init
 * @return {Promise<Response>}
 */
const baseFetch = (input, init = {}) => {
    if (!init.headers) {
        init.headers = {};
    }

    init.headers['X-Requested-With'] = 'fetch';

    return window.fetch(input, init)
        .then(parseHttpResponse)
    ;
};

/**
 * @param {RequestInfo} input
 * @param {Object} body
 * @param {String} method
 * @param {?RequestInit} init
 * @return {Promise<Response>}
 */
const fetchPost = (input, body, method = 'POST', init = {}) => {
    if (!init.headers) {
        init.headers = {};
    }

    init.method = method;
    init.headers['Content-Type'] = 'application/json';
    init.body = JSON.stringify(body);

    return window.fetch(input, init)
        .then(parseHttpResponse)
    ;
};

/**
 * @param {RequestInfo} input
 * @param {?RequestInit} init
 * @return {Promise<Response>}
 */
const fetchDelete = (input, init = {}) => {
    if (!init.headers) {
        init.headers = {};
    }

    init.method = 'DELETE';

    return window.fetch(input, init)
        .then(parseHttpResponse)
    ;
};

export {
    baseFetch as fetch,
    fetchPost,
    fetchDelete,
}
